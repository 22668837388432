import React, { Component } from "react"
import { logout } from "../services/auth"

class LogoutService extends Component {
  render() {
    logout()
    return <></>
  }
}
export default LogoutService
